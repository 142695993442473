<template>
  <v-data-table 
    :headers="headers" 
    :items="agents" 
    :search="search"
    :loading="!agents"
    :loading-text="$t('loading')"
    sort-by="active,code" class="elevation-1"
    :footer-props="{ showFirstLastPage: true, firstIcon: 'mdi-page-first', lastIcon: 'mdi-page-last', prevIcon: 'mdi-chevron-left', nextIcon: 'mdi-chevron-right' }"
  >
    <!-- @click:row="handleRow" -->
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title v-t="'agents'"></v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" single-line hide-details></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" to="agents/new" v-t="'add'"></v-btn>
        <!-- Delete -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">{{ $t('deleteText', {param:$t('agent')}) }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete" v-t="'cancel'"></v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm" v-t="'confirm'"></v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
    </template>
    
    <template v-slot:item.code="{ item }">
      <div @click="redirectTo(item)" class="pointer">
        {{ item.code }}
      </div>
    </template>

    <template v-slot:item.active="{ item }">
      <BooleanIcon :booleanValue="item.active" />
    </template>

    <template v-slot:item.name="{ item }">
      <div @click="redirectTo(item)" class="pointer">
        {{ item.name }}
      </div>
    </template>
       
    <!-- Actions -->
    <template
      v-if=" this.$store.state.myself.auth_profile_type === 'sales' || this.$store.state.myself.auth_profile_type === 'admin'"
      v-slot:item.actions="{ item }">
      <v-btn dense icon raised elevation="2" color="secondary"  @click="redirectTo(item)" class="mx-2">
        <v-icon small >
          mdi-pencil
        </v-icon>
      </v-btn>
      <v-btn dense icon raised elevation="2" color="error" @click="handleRow(item, 'delete')">
        <v-icon small>
          mdi-delete
        </v-icon>
      </v-btn>
     
    </template>
    <template v-slot:no-data>
      <NoDataImg/>
    </template>
  </v-data-table>
</template>

<script>
  import { sync, call } from "vuex-pathify";
  import BooleanIcon from '../../components/app/BooleanIcon';
  import NoDataImg from '../../components/NoDataImg.vue';

  export default {
    components: {
      NoDataImg,
      BooleanIcon,
    },
    data: () => ({
      validForm : false,
      birthday : false,
      address : '',
      panel : null,
      disabled : false,
      goal: {
        target: 30000,
        prc: 1,
        gained: 1,
        animate: true
      },
      events: [],
      input: null,
      nonce: 0,
      dialog: false,
      dialogDelete: false,
      dialogView: false,
      search: '',
      customers: [],
      editedIndex: -1,
      viewedItem: {},
      editAgent: {
        birthday: new Date().toISOString().substr(0, 10),
      },
      defaultItem: {}
    }),
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? 'New Agent' : 'Edit Agent'
      },
      headers() {
        return [{
          text: this.$t('code'),
          value: 'code'
        },
        {
          text: this.$t('active'),
          value: 'active'
        },
        {
          text: this.$t('name'),
          value: 'name'
        },
        {
          text: this.$t('address'),
          value: 'address'
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          sortable: false,
          align:"center"
        },
      ]
      },
      agents: sync("agents/agents"),
    },
    watch: {
      dialogView(val) {
        val || this.closeView()
      },
      dialog(val) {
        val || this.close()
      },
      dialogDelete(val) {
        val || this.closeDelete()
      },
    },
    created() {
      this.initialize()
    },
    methods: {
      redirectTo(item) {
        this.$router.push('agents/'+item.id); 
      },
      getPic() {
        return !!this.editAgent && !!this.editAgent.picture? this.editAgent.picture : require('@/assets/profile_empty.svg')
      },
      getAddressData(addressData, placeResultData, id) {
        this[id].address = !!placeResultData? placeResultData.formatted_address : "";
        this[id].locationUrl = placeResultData.url;
        this[id].utcOffset = placeResultData.utc_offset_minutes;
        this[id].city = addressData.locality;
        this[id].postalCode = addressData.postal_code;
        this[id].geometry = {
          "type": "Point",
          "coordinates": [addressData.latitude,addressData.longitude]
        }
        this.getGeoNames( addressData.administrative_area_level_2 )
        .then((res) => {
          if(!!res) {

            let response = ((res.geonames || []).find(el => el.fcode == 'ADM2') || {});

            this[id].state = response.name;
            this[id].country = response.countryName;
            this[id].geonameId = response.geonameId;

          }
        })
        .catch((res) => console.warn("*** Richiesta di informazioni a GeoNames fallita : " + res));
      },
      getColor(param) {
        if (param == 0) return 'red'
        else if (param < 4) return 'orange'
        else return 'green'
      },
      comment() {
        const time = (new Date()).toTimeString()
        this.events.push({
          id: this.nonce++,
          text: this.input,
          time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents, offset) => {
            return ` ${contents.split(' ').map(v => v.charAt(0)).join('')}`
          }),
        });
        this.input = null
      },
      initialize() {
        this.initAgentCollection();
      },
      handleRow(agent, action) {
        switch (action) {
          case 'edit' : 
            this.editItem(agent);
            break;
          case 'delete' :
            return this.deleteItem(agent); 
          default : 
            this.viewItem(agent);
        } 
      },
      viewItem(item) {
        this.editedIndex = this.agents.indexOf(item)
        this.viewedItem = Object.assign({}, item)
        this.dialogView = true
      },
      editItem(item) {
        this.editedIndex = this.agents.indexOf(item)
        this.editAgent = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem(item) {
        this.editedIndex = this.agents.indexOf(item)
        this.editAgent = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm() {
        this.apiAgentIdDelete(this.editAgent.id)
        .finally(() => this.closeDelete())
      },

      closeView() {
        this.dialogView = false
        this.$nextTick(() => {
          this.editAgent = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      close() {
        this.$refs.autocomplete.clear();
        this.dialog = false;
        this.$nextTick(() => {
          this.editAgent = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete() {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editAgent = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      ...call("agents/*"),
      ...call("utils/*"),
    },
  }
</script>


<style>
  .label {
    font-size: 15px;
    color: grey;
  }
</style>